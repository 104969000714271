import { Injectable } from '@angular/core';
import { HotToastService, ToastOptions } from '@ngneat/hot-toast';

@Injectable({
	providedIn: 'root',
})
export class BsHotToastService {
	constructor(private readonly toast: HotToastService) {}

	show(message: string) {
		this.toast.show(message);
	}

	showError(message: string) {
		this.toast.show(message, {
			icon: undefined,
			className: 'bs error',
			position: 'bottom-right',
			dismissible: true,
			duration: 10000,
		});
	}

	showSuccess(message: string) {
		this.toast.show(message, {
			icon: undefined,
			className: 'bs success',
			position: 'bottom-right',
			dismissible: true,
			duration: 2000,
		});
	}

	showWarning(message: string) {
		this.toast.show(message, {
			icon: undefined,
			className: 'bs warning',
			position: 'bottom-right',
			dismissible: true,
		});
	}

	showInfo(message: string) {
		this.toast.info(message);
	}

	showCustom(message: string, type: ToastOptions<any>) {
		this.toast.show(message, type);
	}
}
