import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { VisiblePasswordDirective } from './directives/visible-password/visible-password.directive';
import { VisiblePasswordDirective2 } from './directives/visible-password2/visible-password2.directive';
@NgModule({
	declarations: [VisiblePasswordDirective, VisiblePasswordDirective2],
	exports: [VisiblePasswordDirective, VisiblePasswordDirective2],
	imports: [CommonModule],
})
export class VisiblePasswordWidgetModule {}
