import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs';

@Component({
	selector: 'bs-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	previousUrl: string = '';
	currentUrl: string = '';

	constructor(readonly router: Router) {}

	ngOnInit(): void {
		this.router.events
			.pipe(
				filter((e) => e instanceof RoutesRecognized),
				pairwise()
			)
			.subscribe((events: any[]) => {
				sessionStorage.setItem('lastUrl', events[0].urlAfterRedirects);
			});
	}
}
