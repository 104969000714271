<section class="white-card">
	<h1 class="highlight-form-title">Tela de bloqueio</h1>
	<p>Faça o login para ter acesso ao portal de atendimento.</p>
	<div class="user-icon">
		<img src="../../../../assets/svgs/rachel.svg" alt="" />
		<h2 *ngIf="data.user?.nome">{{ data.user.nome }}</h2>
	</div>

	<div class="input">
		<mat-form-field appearance="outline">
			<input
				[formControl]="password"
				[type]="bsVisiblePassword.inputType"
				matInput
				placeholder="Digite a sua senha"
			/>

			<mat-icon
				matSuffix
				bsVisiblePassword
				#bsVisiblePassword="bsVisiblePassword"
				class="material-icons-outlined"
				>{{ bsVisiblePassword.iconName }}</mat-icon
			>
		</mat-form-field>
		<div class="button-group">
			<button
				(click)="submitForm()"
				[disabled]="password.invalid"
				mat-raised-button
				color="primary"
			>
				ENTRAR
			</button>
		</div>
	</div>

	<div class="help">
		<span>Não consegue recuperar a sua conta?</span>
		<span
			><span class="blue">Entre em contato</span> connosco que iremos te
			ajudar!</span
		>
	</div>
</section>
