import { HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { untilDestroyed } from '@ngneat/until-destroy';
import {
	BehaviorSubject,
	EMPTY,
	Observable,
	catchError,
	of,
	switchMap,
	tap,
} from 'rxjs';
import { BoxOptionsService } from 'src/app/widget/bs-search/tokens/box.token';
import { ClienteToReturn } from 'src/shared/models/cliente';
import {
	AssociationUsers,
	IUserToRegister,
	TableUser,
	User,
	UserInfoToReturn,
	UserToChangePassword,
	UserToLogin,
	UserToRegister,
	UserToResetPassword,
	UserToReturn,
	newUser,
} from 'src/shared/models/user';
import { NgxCookieService } from '../../ngx-cookie/ngx-cookie.service';
import { BaseApiService } from '../base/base.api.service';
import { ColaboradorToUpdate } from 'src/shared/models/colaborador';
import { BsHotToastService } from '../../hot-toast/hot-toast.service';
import { Router } from '@angular/router';

export interface Acessos {
	ativo: boolean;
	seqMenu: number;
	seqTela: number;
}

@Injectable({
	providedIn: 'root',
})
export class UserApiService
	extends BaseApiService<UserApiService>
	implements BoxOptionsService
{
	readonly CLIENT_EXTENSION = 'cliente/';
	readonly LOGIN_EXTENSION = 'login';
	readonly REGISTER_EXTENSION = 'register';
	readonly PARCIAL_EXTENSION = 'register';
	readonly CHANGE_PASSWORD_EXTENSION = 'redefine-password';
	readonly RESET_PASSWORD_EXTENSION = 'resetpassword';
	readonly RESET_INITIAL_PASSWORD_EXTENSION = 'change-initial-password';
	readonly TOKEN_KEY = 'token';
	readonly EMAIL_EXIST_EXTENSION = 'emailexists';
	readonly CURRENT_EXTENSION = 'current';
	readonly FORGOT_PASSWORD_EXTENSION = 'forgot-password?login=';
	readonly ACESSOS_EXTENSION = 'acessos';
	readonly USER_INFO_EXTENSION = 'get-by-id';
	readonly UPDATE_USER_EXTENSION = 'update';
	readonly DESACTIVATE = 'desactivate';
	readonly GET_ALL = 'get-all';

	headers: HttpHeaders;
	currentUser$$ = new BehaviorSubject<User | null>(null);
	currentUser$ = this.currentUser$$.asObservable();
	acessos: { ativo: boolean; seqTela: number; seqMenu: number }[] | null;

	constructor(
		override readonly injector: Injector,
		private cookieService: NgxCookieService,
		private readonly hotToast: BsHotToastService,
		private readonly router: Router
	) {
		super('usuario', injector);
		const login = this.cookieService.getCookie('email');
		const senha = this.cookieService.getCookie('password');
		const token = this.getToken();
		const httpHeaders = new HttpHeaders();
		this.headers = httpHeaders.set('Authorization', `Bearer ${token}`);
		this.loadCurrentUser(token)
			.pipe(
				switchMap((user) => {
					if (login && senha) {
						return this.login({ login, senha });
					}

					return of(user);
				})
			)
			.subscribe();
	}

	getUsers = () => this.getAll<UserToReturn>();
	deleteUser = (id: string | number) => this.delete<boolean>(id);

	getOptions(): Observable<AssociationUsers[]> {
		return this.getAssociationUsers();
	}

	getCurrentUser(): Observable<User | null> {
		return this.currentUser$.pipe(untilDestroyed(this));
	}

	getUserByEmail(email: string): Observable<UserToReturn> {
		const headers = this.headers;

		return this.httpClient.get<UserToReturn>(
			`${this.BASE_URL}${this.CONTEXT}/email?email=${email}`,
			{ headers }
		);
	}

	getAcessosByUsuario(id: number | string) {
		const queryString = this.getQueryString({ seqUsuario: id });
		return this.httpClient
			.get<Acessos[]>(
				`${this.BASE_URL}${this.CONTEXT}/${this.ACESSOS_EXTENSION}?${queryString}`
			)
			.pipe(
				tap((acessos: Acessos[]) => {
					this.acessos = acessos;
				})
			);
	}

	getUserInfo(id: number | string) {
		const queryString = this.getQueryString({ seqUsuario: id });
		return this.httpClient.get<UserInfoToReturn>(
			`${this.BASE_URL}${this.CONTEXT}/${this.USER_INFO_EXTENSION}?${queryString}`
		);
	}

	getUserbyId(id: number | string) {
		const queryString = this.getQueryString({ seqUsuario: id });
		return this.httpClient.get<newUser>(
			`${this.BASE_URL}${this.CONTEXT}/${this.USER_INFO_EXTENSION}?${queryString}`
		);
	}

	desactivateUser(id: number | string) {
		const queryString = this.getQueryString({ usuarioId: id });
		return this.httpClient.patch<boolean>(
			`${this.BASE_URL}${this.CONTEXT}/${this.DESACTIVATE}?${queryString}`,
			{}
		);
	}

	updateUser(userToUpdate: IUserToRegister) {
		return this.httpClient.put(
			`${this.BASE_URL}${this.CONTEXT}/${this.UPDATE_USER_EXTENSION}`,
			userToUpdate
		);
	}

	getUserAcessos() {
		if (!this.currentUser$$.value) return EMPTY;

		if (this.acessos) return of(this.acessos);

		return this.getAcessosByUsuario(this.currentUser$$.value!.seq_usuario);
	}

	getUserClienteById(id: number): Observable<ClienteToReturn> {
		const headers = this.headers;

		return this.httpClient.get<ClienteToReturn>(
			`${this.BASE_URL}${this.CONTEXT}/${this.CLIENT_EXTENSION}${id}`,
			{
				headers,
			}
		);
	}

	login(userToLogin: UserToLogin) {
		return this.httpClient
			.post<User>(
				`${this.BASE_URL}${this.CONTEXT}/${this.LOGIN_EXTENSION}`,
				userToLogin
			)
			.pipe(
				untilDestroyed(this),
				tap((user: User) => {
					this.currentUser$$.next(null as any);
					if (!user) return;
					this.headers = this.headers.set(
						'Authorization',
						`Bearer ${user.token}`
					);
					localStorage.setItem(this.TOKEN_KEY, user.token);
					this.currentUser$$.next(user);
				})
			);
	}

	forgotPassword(email: string) {
		return this.httpClient.post(
			`${this.BASE_URL}${this.CONTEXT}/${
				this.FORGOT_PASSWORD_EXTENSION
			}${email.replace('@', '%40')}`,
			{}
		);
	}

	changePassword(UserToChangePassword: UserToChangePassword) {
		return this.httpClient.post<User>(
			`${this.BASE_URL}${this.CONTEXT}/${this.CHANGE_PASSWORD_EXTENSION}`,
			UserToChangePassword
		);
	}

	resetPassword(UserToResetPassword: UserToResetPassword) {
		return this.httpClient.post<User>(
			`${this.BASE_URL}${this.CONTEXT}/${this.RESET_PASSWORD_EXTENSION}`,
			UserToResetPassword
		);
	}

	changeInitialPassword(UserToResetPassword: UserToResetPassword) {
		return this.httpClient.post<User>(
			`${this.BASE_URL}${this.CONTEXT}/${this.RESET_INITIAL_PASSWORD_EXTENSION}`,
			UserToResetPassword
		);
	}

	register(userToRegister: IUserToRegister): Observable<boolean> {
		return this.httpClient.post<boolean>(
			`${this.BASE_URL}${this.CONTEXT}/${this.REGISTER_EXTENSION}`,
			userToRegister
		);
	}

	updateCurrentUser(colaboradorToUpdate: ColaboradorToUpdate) {
		return this.httpClient.put(
			`${this.BASE_URL}${this.CONTEXT}/${this.UPDATE_USER_EXTENSION}`,
			colaboradorToUpdate
		);
	}

	updateParcial(user: UserToRegister) {
		return this.httpClient.put(
			`${this.BASE_URL}${this.CONTEXT}/${this.PARCIAL_EXTENSION}`,
			user
		);
	}

	emailExists(email: string): Observable<boolean> {
		const headers = this.headers;

		return this.httpClient.get<boolean>(
			`${this.BASE_URL}${this.CONTEXT}/${this.EMAIL_EXIST_EXTENSION}?email='${email}`,
			{ headers }
		);
	}

	loadCurrentUser(token: string | null): Observable<User | null> {
		if (token === null) {
			this.currentUser$$.next(null);
			return of(null);
		}
		const headers = this.headers;
		return this.httpClient
			.get<User>(`${this.BASE_URL}${this.CONTEXT}/${this.CURRENT_EXTENSION}`, {
				headers,
			})
			.pipe(
				tap((user: User) => {
					this.headers = this.headers.set(
						'Authorization',
						`Bearer ${user.token}`
					);
					this.currentUser$$.next(user);
				}),
				catchError(() => {
					this.currentUser$$.next(null);
					return of(null);
				})
			);
	}

	getToken(): string | null {
		return localStorage.getItem(this.TOKEN_KEY);
	}

	getAllUsers(seqCliente: number, apelido: string, status: number) {
		const queryString = this.getQueryString({
			seqCliente: seqCliente,
			apelido: apelido,
			status: status?.toString(),
		});
		return this.httpClient.get<TableUser[]>(
			`${this.BASE_URL}${this.CONTEXT}?${queryString}`
		);
	}

	getAssociationUsers() {
		return this.httpClient.get<AssociationUsers[]>(
			`${this.BASE_URL}${this.CONTEXT}/${this.GET_ALL}`
		);
	}

	logoff() {
		this.hotToast.showWarning('Logged out!');
		this.cookieService.deleteCookie('email');
		this.cookieService.deleteCookie('password');
		localStorage.removeItem('token');
		localStorage.removeItem('acessos');
		sessionStorage.removeItem('email');
		sessionStorage.removeItem('password');
		sessionStorage.removeItem('permissoes');
		this.currentUser$$.next(null as any);
		this.acessos = null as any;
		this.router.navigate(['./auth']);
	}
}
