import { Injectable } from '@angular/core';
import { EmailValidator } from '@angular/forms';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Route,
	Router,
	RouterStateSnapshot,
	UrlSegment,
} from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { UserApiService } from 'src/app/core/services/api/user/user.api.service';
import { NgxCookieService } from 'src/app/core/services/ngx-cookie/ngx-cookie.service';

@Injectable({
	providedIn: 'root',
})
export class LoggedInGuard implements CanActivate {
	constructor(
		private readonly router: Router,
		private readonly cookieService: NgxCookieService,
		private readonly accountServices: UserApiService
	) {}

	async canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Promise<boolean> {
		const isLoggedIn = await firstValueFrom(this.accountServices.currentUser$);

		const hasEmail = this.cookieService.getCookie('email');
		const hasPassword = this.cookieService.getCookie('password');

		if (hasEmail && hasPassword) {
			await firstValueFrom(
				this.accountServices.login({
					login: hasEmail,
					senha: hasPassword,
				})
			);

			this.router.navigateByUrl('/');

			return false;
		}

		if (!isLoggedIn) {
			const email = sessionStorage.getItem('email');
			const password = sessionStorage.getItem('password');

			if (email && password) {
				await firstValueFrom(
					this.accountServices.login({
						login: email,
						senha: password,
					})
				);
				this.router.navigate(['/']);
				return false;
			}

			return true;
		}

		this.router.navigate(['/']);
		return false;
	}

	async canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
		const isLoggedIn = await firstValueFrom(this.accountServices.currentUser$);

		const hasEmail = this.cookieService.getCookie('email');
		const hasPassword = this.cookieService.getCookie('password');

		if (hasEmail && hasPassword) {
			await firstValueFrom(
				this.accountServices.login({
					login: hasEmail,
					senha: hasPassword,
				})
			);

			this.router.navigateByUrl('/');

			return false;
		}

		if (!isLoggedIn) return true;

		this.router.navigate(['/']);
		return false;
	}
}
