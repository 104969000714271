import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TelaBloqueadaComponent } from './core/components/tela-bloqueada/tela-bloqueada.component';
import { AuthGuard } from './domain/auth/guards/auth.guard';
import { LoggedInGuard } from './domain/auth/guards/logged-in.guard';

const routes: Routes = [
	{
		path: 'auth',
		canActivate: [LoggedInGuard],
		canLoad: [LoggedInGuard],
		loadChildren: async () =>
			(await import('./domain/auth/auth-domain.module')).AuthDomainModule,
	},
	{
		path: 'forgot-password',
		loadChildren: async () =>
			(await import('./domain/forgot-password/forgot-password-domain.module'))
				.ForgotPasswordDomainModule,
	},
	{
		path: 'confirm-account',
		loadChildren: async () =>
			(await import('./domain/forgot-password/forgot-password-domain.module'))
				.ForgotPasswordDomainModule,
	},
	{
		path: '',
		canActivate: [AuthGuard],
		canLoad: [AuthGuard],
		loadChildren: async () =>
			(await import('./domain/home/home-domain.module')).HomeDomainModule,
	},
	{
		path: 'tela-bloqueada',
		component: TelaBloqueadaComponent,
	},
	{
		path: '**',
		redirectTo: '',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true })],
	exports: [RouterModule],
})
export class AppRoutingModule {
	constructor() {}
}
