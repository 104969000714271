import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@ngneat/reactive-forms';
import { catchError } from 'rxjs';
import { AcessosResponse, Permissoes } from 'src/shared/models/permissoes';
import { UserApiService } from '../../services/api/user/user.api.service';
import { BsHotToastService } from '../../services/hot-toast/hot-toast.service';

@Component({
	selector: 'bs-tela-bloqueada',
	templateUrl: './tela-bloqueada.component.html',
	styleUrls: ['./tela-bloqueada.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelaBloqueadaComponent {
	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: {
			user: {
				nome?: string | undefined;
				login?: string | undefined;
				token?: string | undefined;
				role?: string | undefined;
				sexo?: string | undefined;
				seq_usuario?: number | undefined;
				seq_funcionario?: number | undefined;
				id?: number | undefined;
				grupo?: string | undefined;
				acessos?: AcessosResponse[] | undefined;
				permissoesGrupo?: Permissoes | undefined;
				permissoesUser?: Permissoes | undefined;
			};
		},
		private readonly userApiService: UserApiService,
		private readonly hotToast: BsHotToastService,
		private readonly matDialogRef: MatDialogRef<TelaBloqueadaComponent>
	) {}

	password = new FormControl<string>();

	submitForm() {
		if (this.password.invalid || !this.data.user.login) return;

		this.userApiService
			.login({
				login: this.data.user.login,
				senha: this.password.value,
			})
			.pipe(
				catchError((error) => {
					this.hotToast.showError(error.error[0].message);
					throw error;
				})
			)
			.subscribe((user) => {
				this.hotToast.showSuccess('Login realizado com sucesso!');
				this.matDialogRef.close();
			});
	}
}
