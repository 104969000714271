import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	CanLoad,
	Route,
	Router,
	RouterStateSnapshot,
	UrlSegment,
} from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { UserApiService } from 'src/app/core/services/api/user/user.api.service';
import { NgxCookieService } from 'src/app/core/services/ngx-cookie/ngx-cookie.service';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
	constructor(
		private readonly accountServices: UserApiService,
		private readonly cookieService: NgxCookieService,
		private readonly router: Router
	) {}
	async canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Promise<boolean> {
		const isLoggedIn = await firstValueFrom(this.accountServices.currentUser$);

		if (isLoggedIn) return true;

		this.router.navigate(['/auth']);
		return false;
	}

	async canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
		const isLoggedIn = await firstValueFrom(this.accountServices.currentUser$);

		if (isLoggedIn) return true;

		this.router.navigate(['/auth']);
		return false;
	}
}
