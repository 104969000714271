import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
	providedIn: 'root',
})
export class NgxCookieService {
	constructor(private cookie: CookieService) {}

	setCookie(name: string, value: string, days?: number) {
		const encrypt = this.encryptCookie(value);
		this.cookie.set(name, encrypt, days);
	}

	getCookie(name: string) {
		const decrypt = this.decryptCookie(this.cookie.get(name));
		return decrypt;
	}

	deleteCookie(name: string) {
		this.cookie.delete(name);
	}

	encryptCookie(value: string) {
		const firstEncrypt = btoa(value);
		const secondEncrypt = firstEncrypt.split('').map((char) => {
			return String.fromCharCode(char.charCodeAt(0) + 1);
		});
		const thirdEncrypt = secondEncrypt.join('');
		return thirdEncrypt;
	}

	decryptCookie(value: string) {
		const firstDecrypt = value.split('').map((char) => {
			return String.fromCharCode(char.charCodeAt(0) - 1);
		});
		const secondDecrypt = firstDecrypt.join('');
		const thirdDecrypt = atob(secondDecrypt);
		return thirdDecrypt;
	}
}
