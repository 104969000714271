import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injector, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HotToastModule } from '@ngneat/hot-toast';
import { LoadingInterceptor } from 'src/shared/interceptors/loading/ loading.interceptors';
import { JwtInterceptor } from '../shared/interceptors/jwt.Interceptor';
import { LogInterceptor } from '../shared/interceptors/log.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TelaBloqueadaComponent } from './core/components/tela-bloqueada/tela-bloqueada.component';
import { VisiblePasswordWidgetModule } from './widget/bs-visible-password/bs-visible-password-widget.module';
import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';

registerLocaleData(ptBr);

@NgModule({
	declarations: [AppComponent, TelaBloqueadaComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		MatFormFieldModule,
		MatButtonModule,
		ReactiveFormsModule,
		VisiblePasswordWidgetModule,
		FormsModule,
		MatInputModule,
		MatIconModule,
		HttpClientModule,
		HotToastModule.forRoot(),
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: LogInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
		{ provide: LOCALE_ID, useValue: 'pt-BR' },
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	static injector: Injector;

	constructor(injector: Injector) {
		AppModule.injector = injector;
	}
}
