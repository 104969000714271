import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
@UntilDestroy()
export class BaseApiService<T> {
	BASE_URL = environment.BASE_URL_API;

	readonly httpClient: HttpClient;
	constructor(protected CONTEXT: String, protected injector: Injector) {
		this.httpClient = injector.get(HttpClient);
	}

	protected getAll<T>(params?: { [param: string]: any }): Observable<T[]> {
		return this.httpClient.get<T[]>(`${this.BASE_URL}${this.CONTEXT}`, {
			params,
		});
	}

	protected getById<T>(id: string | number) {
		return this.httpClient.get<T>(`${this.BASE_URL}${this.CONTEXT}/${id}`);
	}

	protected create<T>(resource: any): Observable<T> {
		return this.httpClient.post<T>(`${this.BASE_URL}${this.CONTEXT}`, resource);
	}

	protected update<T>(resource: any): Observable<T> {
		return this.httpClient.put<T>(`${this.BASE_URL}${this.CONTEXT}`, resource);
	}
	protected updateById<T>(id: string, resource: T): Observable<T> {
		return this.httpClient.put<T>(
			`${this.BASE_URL}${this.CONTEXT}/${id}`,
			resource
		);
	}

	protected delete<T>(id: string | number) {
		return this.httpClient.delete<T>(`${this.BASE_URL}${this.CONTEXT}/${id}`);
	}

	protected insert<T>(resource: any): Observable<T> {
		return this.httpClient.post<T>(
			`${this.BASE_URL}${this.CONTEXT}/insert`,
			resource
		);
	}
	getQueryString(queryParams: { [key: string]: any }) {
		const queryEntries = Object.entries(queryParams);
		const filteredQuery = queryEntries.filter(([key, value]) => !!value);
		const mappedQuery = filteredQuery.map(([key, value]) => {
			if (value === 'null' || !value) return '';
			return `${key}=${value}`;
		});

		return mappedQuery
			.map((value) => {
				if (value.includes('&=')) return value.replace('&=', '');
				return value;
			})
			.join('&');
	}
}
