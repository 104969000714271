import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SpinnerService } from 'src/shared/services/loading/spinner.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
	constructor(private spinnerService: SpinnerService) {}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		if (!req.url.includes('emailexists')) {
			this.spinnerService.busy();
		}
		return next.handle(req).pipe(
			finalize(() => {
				this.spinnerService.idle();
			})
		);
	}
}
