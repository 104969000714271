import { Directive, HostListener } from '@angular/core';

@Directive({
	selector: '[bsVisiblePassword2]',
	exportAs: 'bsVisiblePassword2',
})
export class VisiblePasswordDirective2 {
	inputType: string = 'password';
	iconName = 'visibility_off';
	isVisible: boolean = false;

	@HostListener('click') updateProperties() {
		this.isVisible = !this.isVisible;
		this.inputType = this.isVisible ? 'text' : 'password';
		this.iconName = this.isVisible ? 'visibility' : 'visibility_off';
	}
}
