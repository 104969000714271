import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
	providedIn: 'root',
})
export class SpinnerService {
	spinnerRequestsCount = 0;
	static hasSpinner = false;

	constructor(private spinnerService: NgxSpinnerService) {}

	busy() {
		this.spinnerRequestsCount++;
		this.spinnerService.show(undefined, {
			type: 'line-scale-party',
			size: 'large',
			bdColor: 'rgba(255, 255, 255, 0.85)',
			color: '#005AE6',
		});
	}

	idle() {
		this.spinnerRequestsCount--;
		if (this.spinnerRequestsCount <= 0) {
			this.spinnerRequestsCount = 0;
			this.spinnerService.hide();
		}
	}
}
